<template>
  <div class="interior-review w-full" @click="goToDetail">
    <div class="image w-full" :style="{ backgroundImage: `url(${image})` }" />
    <p class="name">{{ office }}</p>
    <p class="place">{{ address }}</p>
  </div>
</template>

<script>
import utils from "@/utils";

export default {
  name: "InteriorReviewItem",
  props: {
    data: Object,
  },
  methods: {
    goToDetail() {
      this.$router.push({
        name: "InteriorReviewDetail",
        params: { id: this.data.id },
      });
    },
  },
  computed: {
    image() {
      const { hasTradeReviewFiles } = this.data;
      if (hasTradeReviewFiles) {
        const images = hasTradeReviewFiles.map((i) => utils.getImageUrl(i));
        if (images.length > 0) {
          return images[0];
        }
      }
      return "";
    },
    joinOffer() {
      const { hasInteriorJoinOffer } = this.data;
      if (hasInteriorJoinOffer) {
        return hasInteriorJoinOffer;
      }
      return null;
    },
    offer() {
      if (this.joinOffer) {
        const { hasInteriorOffer } = this.joinOffer;
        if (hasInteriorOffer) {
          return hasInteriorOffer;
        }
      }
      return null;
    },
    office() {
      if (this.joinOffer) {
        const { hasInteriorOffice } = this.joinOffer;
        if (hasInteriorOffice) {
          return hasInteriorOffice.title;
        }
      }
      return "";
    },
    address() {
      if (this.offer) {
        const { address } = this.offer;
        const splited = address.split(" ");
        if (splited.length > 1) {
          return `${splited[0]} ${splited[1]} 위치`;
        } else if (splited.length === 1) {
          return `${splited[0]} 위치`;
        }
      }
      return "";
    },
  },
};
</script>

<style scoped lang="scss">
.interior-review {
  margin-bottom: 30px;
  cursor: pointer;

  .image {
    height: 280px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
  }

  .name {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.8px;
    text-align: left;
    color: #222;
    margin-top: 10px;
  }

  .place {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: -0.64px;
    text-align: left;
    color: #7ca3aa;
    margin-top: 9px;
  }
}

@media (max-width: 639px) {
  .interior-review {
    margin-bottom: 20px;
    .image {
      width: 200px;
      height: 150px;
      margin: 0 auto;
    }
    .name {
      font-size: 15px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
    .place {
      font-size: 13px;
      margin-top: 5px;
      max-width: 200px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
</style>
