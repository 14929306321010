<template>
  <InteriorLayout type="review" :onReviewFilter="onFilter">
    <PaginationLayout :lastPage="lastPage" :onPage="onPage">
      <div
        class="interior-review-list-wrapper grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 grid-rows-2 gap-2 sm:gap-4 lg:gap-8 mt-10"
      >
        <InteriorReviewItem v-for="item in data" :key="item.id" :data="item" />
      </div>
    </PaginationLayout>
  </InteriorLayout>
</template>

<script>
import InteriorLayout from "@/components/InteriorLayout.vue";
import PaginationLayout from "@/components/PaginationLayout.vue";
import InteriorReviewItem from "@/components/InteriorReviewItem.vue";
import services from "@/services";

export default {
  name: "InteriorReviewList",
  components: {
    InteriorLayout,
    PaginationLayout,
    InteriorReviewItem,
  },
  data() {
    return {
      data: [],
      currentPage: 1,
      lastPage: 1,
      filter: {},
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      const { data, lastPage } = await services.reviewList({
        paginate: 6,
        page: this.currentPage,
        isInteriorJoinOffer: 1,
        ...this.filter,
      });
      (this.lastPage = lastPage), (this.data = data);
    },
    onPage(value) {
      this.currentPage = value;
    },
    onFilter(value) {
      this.filter = value;
      this.getData();
    },
  },
  watch: {
    currentPage: function() {
      this.getData();
    },
  },
};
</script>

<style lang="scss" scoped></style>
